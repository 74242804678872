.image-gallery-thumbnails-wrapper {
    /* background: #fff; */
    /* padding-left: 5px;
    padding-right: 5px; */
    margin-bottom: 1em;

    @apply rounded-lg border-[1px] dark:border-white/20 border-black/20 bg-gradient-to-t dark:from-purple-500/40 dark:to-pink-500/40 from-orange-300 to-red-400;
    /* @apply border-y-[1px] border-t-pink-400/30 border-b-pink-300/30 dark:border-t-pink-600/30 dark:border-b-pink-400/30; */
}

.image-gallery-thumbnails-container {
    /* @apply items-start place-items-start justify-items-start; */
    /* @apply absolute h-[200px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 */
}